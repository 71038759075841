import _ from 'lodash'
import { Form, FormInstance } from 'antd'

/** 响应式的 form.getFieldsValue(true) */
export const useFormAllValues = <T extends Record<string, any> | any[]>(
  form: FormInstance<T>,
  initialFormValues: T,
): T => {
  return (
    Form.useWatch(() => {
      const values = form.getFieldsValue(true)
      return _.isEmpty(values) ? initialFormValues : values
    }, form) || initialFormValues
  )
}
