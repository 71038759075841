import { useTranslation } from 'react-i18next'
import { Descriptions, Modal, Space, theme } from 'antd'
import type { DescriptionsItemType } from 'antd/es/descriptions'

import { globalPopupSlot } from '../globalPopupSlot'

const updatePopup = globalPopupSlot.insert(null)

/** 成功与失败结果弹窗 props */
export interface ISuccessAndFailResultModalProps {
  result: {
    /** 失败数量	*/
    failTotal: number
    /** 成功数量 */
    successTotal: number
    /** 失败原因	*/
    failReason?: string | null
  }
  onClose?: () => void
}

/** 成功与失败结果弹窗 */
export const SuccessAndFailResultModal = Object.assign(
  ({ result, onClose, destroy }: ISuccessAndFailResultModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { token } = theme.useToken()
    const { open, onHide, afterOpenChange } = globalPopupSlot.useAntdPopupAnimation(destroy)
    const { successTotal, failTotal, failReason } = result
    const items: DescriptionsItemType[] = []

    items.push({
      label: t('2-common.cheng-gong'),
      children: (
        <Space direction="vertical">
          <div style={{ color: successTotal > 0 ? token.colorSuccess : undefined }}>
            {t('6-comp.gong-cheng-gong-total-tiao', { total: successTotal || 0 })}
          </div>
        </Space>
      ),
    })

    const errMsgs = `${failReason || ''}`.split(/\n+/g)
    items.push({
      label: t('2-common.shi-bai'),
      children: (
        <Space direction="vertical">
          <div style={{ color: failTotal > 0 ? token.colorError : undefined }}>
            {t('6-comp.gong-shi-bai-total-tiao', { total: failTotal || 0 })}
          </div>
          {!!errMsgs.length && (
            <div style={{ color: token.colorError }}>
              {errMsgs.map((msg, i) => (
                <div key={i}>{msg}</div>
              ))}
            </div>
          )}
        </Space>
      ),
    })

    return (
      <Modal
        title={t('6-comp.cao-zuo-jie-guo')}
        width={500}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          onHide()
          onClose?.()
        }}
        open={open}
        onCancel={() => {
          onHide()
          onClose?.()
        }}
        afterOpenChange={afterOpenChange}
      >
        <Descriptions bordered column={1} items={items} />
      </Modal>
    )
  },
  {
    open: (props: ISuccessAndFailResultModalProps) => {
      updatePopup(<SuccessAndFailResultModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
